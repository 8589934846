export default [
  {
    id: 0,
    name: "",
    description: "Kess Game Engine",
    image: "images/projects/img_1.png",
    tags: ["Unity", "Blender", "CSharp"],
    liveview: "https://kessgame.com",
    alt: "Kess Chess Game Project",
  },
  {
    id: 1,
    name: "",
    description: "3313 RTS Game",
    image: "images/projects/img_2.png",
    tags: ["Unity", "CSharp", "ThreeDSMax", "RTSEngine"],
    liveview: "https://gitlab.com/PhilipJohn82/3313",
    alt: "3313 RTS Game Project",
  },
  {
    id: 3,
    name: "",
    description: "MR Tennis Game",
    image: "images/projects/img_3.png",
    tags: ["Unity", "CSharp", "VR", "OculusQuest3"],
    liveview: "https://gitlab.com/PhilipJohn82/TennisVR",
    alt: "Mixed Reality Tennis Game Project",
  },
  {
    id: 4,
    name: "",
    description: "King Shooter",
    liveview: "https://gitlab.com/PhilipJohn82/MultiShootGame5.2",
    image: "images/projects/img_4.png",
    tags: ["UnrealEngine", "C", "FPSEngine", "Blender"],
    alt: "Single FPS Shooter Game Project",
  },
  {
    id: 5,
    name: "",
    description: "Berserk Reborn",
    liveview: "https://gitlab.com/PhilipJohn82/Berserk-Reborn",
    image: "images/projects/img_5.png",
    tags: ["Unity", "CSharp", "Photoshop", "Animations"],
    alt: "Card Battle Game Project",
  },
  {
    id: 6,
    name: "",
    description: "Mansion Battle",
    image: "images/projects/img_6.jpg",
    liveview: "https://github.com/PhilipJohn82/Mansion",
    tags: ["Unity", "CSharp", "Photoshop", "ThreeDSMax", "AI"],
    alt: "Mansion Battle Fighting Game Project",
  },
  {
    id: 7,
    name: "",
    description: "Head Ball Soccer",
    image: "images/projects/img_7.png",
    liveview: "https://github.com/PhilipJohn82/Head-Ball5-Game",
    tags: ["Unity", "CSharp", "Photoshop", "AI"],
    alt: "",
  },
  {
    id: 8,
    name: "",
    description: "Lyra",
    image: "images/projects/img_8.png",
    liveview: "https://gitlab.com/PhilipJohn82/LyraGame",
    tags: ["UnrealEngine", "C", "GameKit", "TPSEngine"],
    alt: "",
  },
  {
    id: 9,
    name: "",
    description: "Battle Royale 200",
    image: "images/projects/img_9.png",
    liveview: "https://gitlab.com/PhilipJohn82/BR200",
    tags: ["Unity", "CSharp", "PhotonFusion", "TPSEngine"],
    alt: "",
  },
  {
    id: 10,
    name: "",
    description: "KOI",
    image: "images/projects/img_10.png",
    liveview: "https://gitlab.com/PhilipJohn82/KOI",
    tags: ["Unity", "CSharp", "PlayFab", "StoryMode"],
    alt: "",
  },
  {
    id: 11,
    name: "",
    description: "K.O.Racing",
    image: "images/projects/img_11.png",
    liveview: "https://gitlab.com/PhilipJohn82/K.O.Racing",
    tags: ["UnrealEngine", "C", "Racing"],
    alt: "",
  },
  {
    id: 12,
    name: "",
    description: "NEON HEAT",
    image: "images/projects/img_12.png",
    liveview: "https://github.com/PhilipJohn82/NEON-CAR",
    tags: ["Unity", "CSharp", "MapEditor"],
    alt: "",
  },
  {
    id: 13,
    name: "",
    description: "Remind Me",
    image: "images/projects/img_13.png",
    liveview: "1",
    tags: ["Unity", "Notifi", "Face"],
    alt: "",
  },
  {
    id: 14,
    name: "",
    description: "TryOn3D",
    image: "images/projects/img_14.png",
    liveview: "https://nftshowroom.com/",
    tags: ["Unity", "CSharp", "Android", "iOS", "AR"],
    alt: "",
  },
  {
    id: 15,
    name: "",
    description: "Social QR",
    image: "images/projects/img_15.png",
    liveview: "https://nftshowroom.com/",
    tags: ["Unity", "CSharp", "Android", "iOS", "Zapworks"],
    alt: "",
  },
  {
    id: 16,
    name: "",
    description:
      "3D Game Modelling",
    image: "images/projects/img_17.jpg",
    liveview: "https://nftshowroom.com/",
    tags: ["Blender", "ThreeDSMax", "Photoshop"],
    alt: "",
  },
  {
    id: 17,
    name: "",
    description:
    "3D Game Modelling",
    image: "images/projects/img_18.jpg",
    liveview: "https://nftshowroom.com/",
    tags: ["Blender", "ThreeDSMax", "Photoshop"],
    alt: "",
  },
  {
    id: 18,
    name: "",
    description:
    "3D Game Modelling",
    image: "images/projects/img_19.jpg",
    liveview: "https://nftshowroom.com/",
    tags: ["Blender", "ThreeDSMax", "Photoshop"],
    alt: "",
  },
  {
    id: 19,
    name: "",
    description:
      "Mow Mission Design",
    image: "images/projects/img_20.png",
    liveview: "https://nftshowroom.com/",
    tags: ["GemEditor"],
    alt: "",
  },
  {
    id: 20,
    name: "",
    description:
    "Mow Mission Design",
    image: "images/projects/img_21.png",
    liveview: "https://nftshowroom.com/",
    tags: ["GemEditor"],
    alt: "",
  },
  
  {
    id: 21,
    name: "",
    description:
    "Mow Mission Design",
    image: "images/projects/img_22.png",
    liveview: "https://nftshowroom.com/",
    tags: ["GemEditor"],
    alt: "",
  },
  
];