export default [
    {
        name: 'Unreal Engine',
        width: '90%',
    },
    {
        name: 'Unity 3D & 2D',
        width: '80%',
    },
    {
        name: 'CryEngine',
        width: '60%',
    },
    {
        name: 'Photoshop',
        width: '80%'
    },
    {
        name: 'Blender',
        width: '70%',
    },
    {
        name: 'Cocos 3D & 2D',
        width: '90%',
    },
    {
        name: 'GameMaker',
        width: '70%',
    },
    {
        name: 'Good Communication',
        width: '100%',
    }
]