export default {
  PlayFab: `<div class="work-item-tag" style="background: #80bf99;">Playfab & CBS</div>`,
  StoryMode: `<div class="work-item-tag" style="background: #4785b8;">Story Mode</div>`,
  MapEditor: `<div class="work-item-tag" style="background: #5A69EC;">Map Editor</div>`,
  Racing: `<div class="work-item-tag" style="background: #f2f29e;">Racing</div>`,
  PhotonFusion: `<div class="work-item-tag" style="background: #8cffbf;">Photon Fusion</div>`,
  TPSEngine: `<div class="work-item-tag" style="background: #1a8ce6;">TPS Engine</div>`,
  AI: `<div class="work-item-tag" style="background: #7a33f0;">AI</div>`,
  Animations: `<div class="work-item-tag" style="background: #6beb75;">Animations</div>`,
  C: `<div class="work-item-tag" style="background: #7da3ab;">C/C++</div>`,
  FPSEngine: `<div class="work-item-tag" style="background: #eb2999;">FPS Engine</div>`,
  Unity: `<div class="work-item-tag" style="background: #85d147;">Unity</div>`,
  ThreeDSMax: `<div class="work-item-tag" style="background: #1affa3;">3DS Max</div>`,
  VR: `<div class="work-item-tag" style="background: #ff9933;">VR</div>`,
  AR: `<div class="work-item-tag" style="background: #ff9933;">AR</div>`,
  UnrealEngine: `<div class="work-item-tag" style="background: #21BAEB;">Unreal Engine</div>`,
  Photoshop: `<div class="work-item-tag" style="background: #CA49F8;">Photoshop</div>`,
  Blender: `<div class="work-item-tag" style="background: #FFB800;">Blender</div>`,
  GameKit: `<div class="work-item-tag" style="background: #8433CC;">GameKit</div>`,
  RTSEngine: `<div class="work-item-tag" style="background: #ff33ff;">RTS ENGINE</div>`,
  OculusQuest3: `<div class="work-item-tag" style="background: #f2076e;">Meta Quest 3</div>`,
  GemEditor: `<div class="work-item-tag" style="background: #7accd6;">Gem Editor</div>`,
  CSharp: `<div class="work-item-tag" style="background: #7accd6;">C#</div>`,
  Android: `<div class="work-item-tag" style="background: #80bf99;">Android</div>`,
  iOS: `<div class="work-item-tag" style="background: #808080;">iOS</div>`,
  Notifi: `<div class="work-item-tag" style="background: #FF0000;">Mobile Notifications</div>`,
  Face: `<div class="work-item-tag" style="background: #FFA500;">Face Recoginition</div>`,
  Zapworks: `<div class="work-item-tag" style="background: #073158;">Zapworks</div>`,
};
